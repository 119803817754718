import dateTimeFormatter from '@/models/common/date-time-formatter';
import generalFunctions from '@/models/common/general-functions';

export default {
  name: 'WitnessPieLegend',
  components: {
  },
  props: {
    data: Array,
    loading: Boolean,
    metadata: Object,
    nameKey: String,
    itemKey: String,
    modeKey: String,
    selected: Array,
    multiClinic: Boolean
  },
  data () {
    return {
      keyword: '',
      selectedInternal: [],
      dataInternal: this.data
    };
  },
  computed: {
    header_meta: function () {
      let temp = [];
      if (this.multiClinic && this.nameKey != 'witness_point_name' && this.nameKey != 'clinic_comparison_name') {
        // temp.push({ value: 'clinicid', text: 'Clinic', sortable: true, align: 'start', width: '30%'  });
        temp.push({ value: 'clinicid', text: 'Clinic', sortable: true, align: 'start', width: '200px' });
      }
      if (this.modeKey === 'count') {
        temp.push(
          { value: 'color', text: '', sortable: false, align: 'center' },
          { value: this.nameKey, text: this.friendlySubjectName, sortable: true, align: 'start' },
          { value: 'count', text: 'Count', sortable: true, align: 'end' },
          // { value: 'percent', text: '%', sortable: false, align: 'end', width: '6%' }
          { value: 'percent', text: '%', sortable: false, align: 'end' }
        );
      } else if (this.modeKey === 'average_duration') {
        temp.push(
          { value: 'color', text: '', sortable: false, align: 'center' },
          { value: this.nameKey, text: this.friendlySubjectName, sortable: true, align: 'start' },
          { value: 'countwithduration', text: 'Count', sortable: true, align: 'end' },
          { value: 'average_duration', text: 'Avg. duration', sortable: true, align: 'end' },
          // { value: 'percent', text: '%', sortable: false, align: 'end', width: '6%' }
          { value: 'percent', text: '%', sortable: false, align: 'end' }
        );
      } else if (this.modeKey === 'total_time') {
        temp.push(
          { value: 'color', text: '', sortable: false, align: 'center' },
          { value: this.nameKey, text: this.friendlySubjectName, sortable: true, align: 'start' },
          { value: 'countwithduration', text: 'Count', sortable: true, align: 'end' },
          { value: 'total_time', text: 'Total time', sortable: true, align: 'end' },
          // { value: 'percent', text: '%', sortable: false, align: 'end', width: '6%' }
          { value: 'percent', text: '%', sortable: false, align: 'end' }
        );
      }

      return temp;
    },
    friendlySubjectName: function () {
      if (this.nameKey === 'witness_point_name') {
        return 'Witness Point';
      } else if (this.nameKey === 'witness_location_name') {
        return 'Location';
      } else if (this.nameKey === 'clinic_comparison_name') {
        return 'Clinic Name';
      } else {
        return 'Operator';
      }
    },
    addAdditionalColumn: function() {
      return this.multiClinic && this.nameKey != 'witness_point_name' && this.nameKey != 'clinic_comparison_name'; 
    }
  },
  methods: {
    formatDT: dateTimeFormatter.deconstructDateTimeSeconds,
    updateClinicIdToClinicName: function (clinicId) {
      return generalFunctions.getClinicNameFromClinicGuid(clinicId);
    }
  },
  watch: {
    selected: function (newVal, oldVal) {
      this.selectedInternal = newVal;
    },
    data: function (newVal, oldVal) {
      this.dataInternal = this.data;
    }
  }
};