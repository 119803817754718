import apiEndpoints from '@/models/common/api-endpoints';
import dateTimeFormatter from '@/models/common/date-time-formatter';
import dayjs from 'dayjs';
import generalFunctions from '@/models/common/general-functions';

export default {
  name: 'witness_raw_data',
  props: {
    clinicIds: Array,
    nonProcedureEvents: Object,
    events: Array,
    locations: Array,
    operators: Array,
    startDate: String,
    endDate: String,
    updateDataTable: Boolean,
    eventType: {
      type: String,
      default: 'witness'
    },
    cardTitleOverride: {
      type: String,
      default: undefined
    },
    tableHeightOverride: {
      type: Number,
      default: undefined
    },
    itemsPerPageOverride: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      data: {},
      returnedData: undefined,
      dataCache: [],
      dataItems: [],
      dataLength: 0,
      dataHeaders: [
        { value: 'clinicid', text: 'Clinic', filterable: true, sortable: false },
        { value: 'witnesspoint', text: 'Event name', filterable: true, sortable: false },
        { value: 'datetime', text: 'Recorded at', filterable: false, sortable: false },
        { value: 'operator', text: 'Operator', filterable: true, sortable: false },
        { value: 'location', text: 'Location', filterable: true, sortable: false },
        { value: 'start', text: 'Started', filterable: false, sortable: false },
        { value: 'end', text: 'Ended', filterable: false, sortable: false },
        { value: 'duration', text: 'Duration', filterable: false, sortable: false, align: 'end' }
      ],
      dataOptions: {},
      cardTitle: 'Raw Witness Data',
      downloadButton: true,
      downloadLoading: false,
      downloadColor: 'rgba(255, 166, 87, 0.6)',
      error: false,
      loading: false,
      untilDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
      maxLength: 40,
      haveAllData: false,
      pageIndexStart: 0,
      pageIndexEnd: 0
      // dataTableRequestCount: 0
    };
  },
  computed: {
    paginationText: function () {
      let output = String(this.pageIndexStart + 1) + ' - ' + String(this.pageIndexEnd) + ' of ';
      if (this.haveAllData) {
        output =  output + String(this.dataCache.length);
      } else {
        output = output + + String(this.dataLength) + ' cached, total unknown.';
      }
      return output;
    },
    findRelevantDataHeaders: function () {
      if (this.clinicIds?.length != 1) {
        return this.dataHeaders;
      } else {
        return this.dataHeaders.filter(header => header.value !== 'clinicid');
      }
    }
  },
  methods: {
    formatDuration: function (duration) {
      if (duration === null) {
        return '';
      }
      return dateTimeFormatter.deconstructDateTimeSeconds(duration);
    },
    formatISODate: function (dateString) {
      if (dateString === '' || dateString === undefined || dateString === null) {
        return '';
      } else {
        // return new Date(dateString).toUTCString().substring(0, 25);
        // return new Date(dateString).toISOString().substring(0,19).replace('T', ' ');
        // return dayjs(dateString).format('ddd, DD MMM YYYY HH:mm:ss');
        return new Date(dateString + 'Z').toUTCString().substring(0, 25);
      }
    },
    loadDataItemsFromCache: function () {
      this.dataItems = this.dataCache.slice(this.pageIndexStart, this.pageIndexEnd);
      this.loading = false;
    },
    updateClinicIdToClinicName: function (clinicGuid) {
      const clinicName = generalFunctions.getClinicNameFromClinicGuid(clinicGuid);
      return clinicName;
    },
    getDataFromApi: async function (getLots = false) {
      // this.dataTableRequestCount += 1;
      // const localDataTableRequestCount = this.dataTableRequestCount;
      const { page, itemsPerPage } = this.dataOptions;
      if ((((itemsPerPage * page) <= this.dataCache.length) || this.haveAllData) && !getLots) {
        // we already have the data, so slice it up...
        // console.log('We want: ' + String(itemsPerPage * page));
        // console.log('We have: ' + String(this.dataCache.length));
        // console.log('We already have the data! Woot!');
        this.dataItems = [];
        this.pageIndexStart = (page - 1) * itemsPerPage;
        this.pageIndexEnd = page * itemsPerPage;
        this.loadDataItemsFromCache();
      } else if (!getLots) {
        // console.log('We want: ' + String(itemsPerPage * page));
        // console.log('We have: ' + String(this.dataCache.length));
        // console.log('We don\'t have the data?!? :( sadface');
        // We don't have the data, so we need to fetch more
        if (this.dataOptions.itemsPerPage && this.startDate && this.endDate) {
          this.loading = true;

          this.pageIndexStart = this.dataCache.length;
          let response = undefined;
          if (this.dataCache.length === 0) {
            // first query, so omit the untilDate
            response = await apiEndpoints.getAllWitnessEvents(this.clinicIds, this.nonProcedureEvents, this.events, this.locations, this.operators, this.startDate, this.endDate, null, this.dataOptions.itemsPerPage + 1);
          } else {
            response = await apiEndpoints.getAllWitnessEvents(this.clinicIds, this.nonProcedureEvents, this.events, this.locations, this.operators, this.startDate, this.endDate, null, this.dataOptions.itemsPerPage + 1, this.untilDate);
          }
          // const response = await apiEndpoints.getAllWitnessEvents({
          //   clinicIds: this.clinicIds,
          //   nonProcedureEvents: this.nonProcedureEvents,
          //   events: this.events,
          //   locations: this.locations,
          //   operators: this.operators,
          //   startDate: this.startDate,
          //   endDate: this.endDate,
          //   groupby: null,
          //   maxItems: this.dataOptions.itemsPerPage + 1,
          //   untilDateTime: this.untilDate
          // });
          // if (localDataTableRequestCount !== this.dataTableRequestCount) {
          //   this.loading = false;
          //   return;
          // }
          if (response.result) {
            this.returnedData = response.data;

            if (response.data.length < this.dataOptions.itemsPerPage + 1) {
              this.haveAllData = true;
            }
            // add response to cache, remove last element of the response if we do not have all the data
            if (this.haveAllData) {
              this.dataCache = this.dataCache.concat(response.data);
              this.maxLength = this.dataCache.length;
            } else {
              response.data.splice(-1, 1);
              this.dataCache = this.dataCache.concat(response.data);
              this.maxLength = this.dataCache.length + 1;
            }
            this.dataLength = this.dataCache.length;
            this.pageIndexEnd = this.dataCache.length;
            // until date for api request set as that of the penultimate item returned in last request
            this.untilDate = this.dataCache[this.dataCache.length - 1]?.datetime;
            this.loadDataItemsFromCache();
          }
          else {
            this.returnedData = null;
          }
          this.loading = false;
        }
      } else {
        this.loading = true;
        const response = await apiEndpoints.getAllWitnessEvents(this.clinicIds, this.nonProcedureEvents, this.events, this.locations, this.operators, this.startDate, this.endDate, null, 500, this.untilDate);
        // const response = await apiEndpoints.getAllWitnessEvents({
        //   clinicIds: this.clinicIds,
        //   nonProcedureEvents: this.nonProcedureEvents,
        //   events: this.events,
        //   locations: this.locations,
        //   operators: this.operators,
        //   startDate: this.startDate,
        //   endDate: this.endDate,
        //   groupby: null,
        //   maxItems: 500,
        //   untilDateTime: this.untilDate
        // });
        if (response.result) {
          if (response.data.length < 500) {
            this.haveAllData = true;
          }
          this.dataCache = this.dataCache.concat(response.data);
          this.untilDate = this.dataCache[this.dataCache.length - 1].datetime;
          this.maxLength = this.dataCache.length;
          this.dataLength = this.dataCache.length;
          this.pageIndexEnd = this.dataCache.length;
        }
        this.loading = false;
      }
    },
    downloadClick: async function () {
      this.downloadLoading = true;

      while (!this.haveAllData) {
        // retrieve all remaining data
        await this.getDataFromApi(true);
      }

      if (this.haveAllData) {
        this.gen_csv();
      }

      this.downloadLoading = false;
    },
    convertToCSV: function (array, headerRow = null) {
      if (!headerRow) {
        // Create the header row dynamically from object keys
        headerRow = Object.keys(array[0]);
      }

      // Loop over the array and output an array in headerRow order for each object
      let csv = array.map(row => {
        return headerRow.map(name => {
          if (typeof row[name] === 'string' || row[name] instanceof String) {
            return '"' + row[name] + '"';
          }
          return row[name];
        });
      });

      // Put the header row back as the first entry in the array
      csv = [headerRow].concat(csv);

      // Generate and return CSV data from the 2d array
      return csv.map(it => {
        return Object.values(it).toString();
      }).join('\n');
    },
    gen_csv: function () {
      const data = JSON.parse(JSON.stringify(this.dataCache)); // cheap deep copy equivalent

      data.forEach(function (item) {
        if (item.color) {
          delete item.color;
        }
        if (item.color_average_duration) {
          delete item.color_average_duration;
        }
        if (item.color_count) {
          delete item.color_count;
        }
        if (item.color_total_time) {
          delete item.color_total_time;
        }
        if (item.eventtype) {
          item.event_type = item.eventtype;
          delete item.eventtype;
        }
        if (item.operator) {
          item.operator_name = item.operator;
          delete item.operator;
        }
        if (item.witnesspoint) {
          item.witness_point = item.witnesspoint;
          delete item.witnesspoint;
        }
        if (item.clinicid) {
          item.clinic_name = generalFunctions.getClinicNameFromClinicGuid(item.clinicid);
          delete item.clinicid;
        }
      });

      const csv = this.convertToCSV(data, ['clinic_name', 'datetime', 'witness_point', 'operator_name', 'location', 'duration', 'start', 'end', 'event_type']);

      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob(['\ufeff' + decodeURIComponent(encodeURI(csv))], {
          type: 'text/csv;charset=utf-8;'
        });
        navigator.msSaveBlob(blob, 'raw_data.csv');
      } else {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + '\ufeff' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'raw_data.csv';
        hiddenElement.click();
        hiddenElement.remove();
      }
    }
  },
  //     /$$      /$$             /$$               /$$
  //    | $$  /$ | $$            | $$              | $$
  //    | $$ /$$$| $$  /$$$$$$  /$$$$$$    /$$$$$$$| $$$$$$$
  //    | $$/$$ $$ $$ |____  $$|_  $$_/   /$$_____/| $$__  $$
  //    | $$$$_  $$$$  /$$$$$$$  | $$    | $$      | $$  \ $$
  //    | $$$/ \  $$$ /$$__  $$  | $$ /$$| $$      | $$  | $$
  //    | $$/   \  $$|  $$$$$$$  |  $$$$/|  $$$$$$$| $$  | $$
  //    |__/     \__/ \_______/   \___/   \_______/|__/  |__/
  //
  watch: {
    'dataOptions.page': function () {
      if(this.dataItems.length != 0) {
        this.getDataFromApi();
      }
    },
    updateDataTable: function (newVal, oldVal) {
      if (newVal === true) {
        this.$emit('tableUpdated');
        this.haveAllData = false;
        this.dataCache = [];
        this.dataItems = [];
        this.untilDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
        this.maxLength = this.dataOptions.itemsPerPage + 1;
        this.pageIndexStart = 0;
        this.pageIndexEnd = 0;
        this.dataOptions.page = 1;
        this.getDataFromApi();              
      }
    }
  },
  mounted () {
  }
};