import dateTimeFormatter from '@/models/common/date-time-formatter';
import generalFunctions from '@/models/common/general-functions';

export default {
  name: 'witness-bar-chart',
  components: {
  },
  props: {
    data: Array,
    loading: Boolean,
    metadata: Object,
    nameKey: String,
    itemKey: String,
    modeKey: String,
    selected: Array,
    multiClinic: Boolean
  },
  data () {
    return {
      dataInternal: this.data
    };
  },
  computed: {
    header_meta: function () {
      let temp = [];
      if (this.multiClinic && this.nameKey != 'clinic_comparison_name' && this.nameKey != 'witness_point_name') {
        // temp = [ { value: 'clinicid', text: 'Clinic', sortable: true, align: 'start', width: '15%' } ];
        temp = [ { value: 'clinicid', text: 'Clinic', sortable: true, align: 'start', width: '200px' } ];
      }
      temp.push(
        // { value: this.nameKey, text: this.friendlySubjectName, sortable: true, align: 'start', width: '22%' },
        // { value: 'countwithduration', text: 'Count', sortable: true, align: 'end', width: '15%' },
        // { value: 'average_duration', text: 'Avg. duration', sortable: true, align: 'end', width: '25%' },
        { value: this.nameKey, text: this.friendlySubjectName, sortable: true, align: 'start', width: '200px' },
        { value: 'countwithduration', text: 'Count', sortable: true, align: 'end' },
        { value: 'average_duration', text: 'Avg. duration', sortable: true, align: 'end' },
        { value: 'bars', text: '', sortable: false, align: 'start', width: '40%' }
      );

      return temp;
    },
    friendlySubjectName: function () {
      if (this.nameKey === 'witness_point_name') {
        return 'Witness Point';
      } else if (this.nameKey === 'witness_location_name') {
        return 'Location';
      } else if (this.nameKey === 'clinic_comparison_name') {
        return 'Clinic';
      } else {
        return 'Operator';
      }
    },
    avgDurationSum: function () {
      // does not take into account the counts for the total, its more for proportions
      let total = 0;
      
      this.data.forEach(element => {
        total += element.average_duration;
      });
      
      return total;
    },
    usingClinicAverages: function () {
      if (this.data !== undefined && this.data.length !== 0) {
        if ('clinic_average_duration' in this.data[0]) {
          return true;
        }
      }
      return false;
    },
    colorToUse: function () {
      if (this.nameKey === 'witness_point_name') {
        return 'rgb(54, 140, 225)';
      }
      if (this.nameKey === 'witness_location_name') {
        return 'rgb(48, 239, 130)';
      }
      if (this.nameKey === 'witness_operator_name') {
        return 'rgb(255, 94, 99)';
      }
      if (this.nameKey === 'clinic_comparison_name') {
        return 'rgb(255, 0, 200)';
      }
      return null;
    },
    addAdditionalColumn: function () {
      return this.multiClinic && this.nameKey != 'witness_point_name' && this.nameKey != 'clinic_comparison_name';
    }
  },
  methods: {
    formatDT: dateTimeFormatter.deconstructDateTimeSeconds,
    updateClinicIdToClinicName: function (clinicId) {
      return generalFunctions.getClinicNameFromClinicGuid(clinicId);
    }
  },
  watch: {
    data: function (newVal, oldVal) {
      this.dataInternal = this.data;
    }
  }
};