import VuePlotly from '@/components/VuePlotly';
import WitnessBarChart from '@/components/WitnessBarChart';
import WitnessPieLegend from '@/components/WitnessPieLegend';


export default {
  name: 'witness_pie',
  components: {
    WitnessPieLegend,
    VuePlotly,
    WitnessBarChart
  },
  props: {
    card_title_base: String,
    titleOverride: String,
    data: Array,
    loading: Boolean,
    metadata: Object,
    autoResize: Boolean,
    nameKey: String,
    modeKey: String,
    itemKey: String,
    dl_button: Boolean,
    dl_color: String,
    multiClinic: Boolean
  },
  data () {
    return {
      pie_layout: {
        margin: {
          l: 0,
          r: 0,
          b: 10,
          t: 0,
          pad: 0
        },
        showlegend: false,
        direction: 'clockwise',
        hoverinfo: 'all'
      },
      pie_options: {
        responsive: true,
        displayModeBar: false,
        showSendToCloud: false,
        showEditInChartStudio: false,
        showLink: false,
        displaylogo: false
      },
      selected: []
    };
  },
  computed: {
    noData: function () {
      if (!this.loading) {
        return (this.data.length === 0);
      }
      return false;
    },
    pieData: function () {
      const mutableData = this.data;

      const tempValues = [];
      const tempLabels = [];
      const tempColors = [];
      const tempFormatted = [];
      const tempIds = [];

      mutableData.forEach(row => {
        tempValues.push(row[this.modeKey]);
        tempLabels.push(row[this.nameKey]);
        tempColors.push(row['color_' + this.modeKey]);
        tempIds.push(row[this.itemKey]);

        if (this.modeKey !== 'count') {
          if (row[this.modeKey] < 60) {
            tempFormatted.push(row[this.modeKey]);
          } else if (row[this.modeKey] < 3600) {
            tempFormatted.push(new Date(row[this.modeKey] * 1000).toISOString().substr(14, 5));
          } else if (row[this.modeKey] < 86400) {
            tempFormatted.push(new Date(row[this.modeKey] * 1000).toISOString().substr(11, 8));
          } else {
            let seconds = row[this.modeKey];
            const days = Math.floor(seconds / (3600 * 24));
            seconds -= days * 3600 * 24;
            const hrs = Math.floor(seconds / 3600);
            seconds -= hrs * 3600;
            const mnts = Math.floor(seconds / 60);
            seconds -= mnts * 60;
            // console.log(days + ' days, ' + hrs + ' Hrs, ' + mnts + ' Minutes, ' + seconds + ' Seconds')
            tempFormatted.push(days + 'd ' + new Date(row[this.modeKey] * 1000).toISOString().substr(11, 8));
          }
        } else {
          tempFormatted.push(row[this.modeKey]);
        }
      });

      // console.log(tempColors)
      const tempData = [{
        name: '',
        values: tempValues,
        labels: tempIds,
        text: tempFormatted,
        ids: tempLabels,
        type: 'pie',
        textinfo: 'none',
        hole: 0.3,
        direction: 'clockwise',
        sort: true,
        hovertemplate: '<b>%{id}</b><br><br>' +
          '%{text}<br>' + '%{percent}<br>', // + '%{value}<br>',
        marker: { colors: tempColors }
      }];

      return tempData;
    },
    cardTitle: function () {
      let title = '';
      if (this.modeKey === 'count') {
        title = 'Number of Events by ' + this.card_title_base;
      } else if (this.modeKey === 'average_duration') {
        title = 'Average Duration of Events by ' + this.card_title_base;
      } else {
        title = 'Total Duration of Events by ' + this.card_title_base;
      }

      if (this.titleOverride) {
        title = this.titleOverride;
      }

      return title;
    }
  },
  methods: {
    emit_dl: function () {
      this.$emit('dl');
    },
    triggerSetSelected: function (payload) {
      // console.log(payload.points[0].label);
      const hovered = payload.points[0].label;

      this.setSelected(hovered);
    },
    setSelected: function (payload) {
      this.data.forEach(function (row) {
        if (row[this.itemKey] === payload) {
          this.selected = [row];
          return true;
        }
      }, this);
    },
    plotlyTest: function () {
      this.$refs.crazyPlotly.Fx.hover();
    }
  },
  mounted () {
  }
};
